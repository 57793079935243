<template>
  <div class="home-page">
    <ul
      class="list-word"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <!-- data.typeList -->
      <cardBody
        :cardObject="item"
        v-for="(item, index) in data.typeList"
        :key="index"
      ></cardBody>
    </ul>
    <div>
      <a class="show-more" @click="showMore">{{ showmorecontent }}</a>
    </div>
  </div>
</template>

<script setup>
import cardBody from "@/components/cardBody";
import { phoneStore } from "../../../store/phone1";
import { reactive, onMounted, ref, watchEffect, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { searchList } from "@/api/center.js";
import { ElMessage } from "element-plus";
import { setToken, getToken, removeToken, getType } from "@/utils/auth";
import bus from "vue3-eventbus";
import request from "@/utils/request.js";

const router = useRouter();
const route = useRoute();
const store1 = phoneStore();
const showmorecontent = ref("Show More");
const pageNum = ref(1);
const pageSize = ref(20);
const loading = ref(true);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const data = reactive({
  type: "",
  typeList: [],
});

watch(window.location.hash
,()=>{
  store1.isSide = false;
})

onMounted(() => {
  store1.isSide = false;
});

const searchListFn = (name) => {
  loading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    cateGory: name,
    status: 3,
  };
  searchList(params).then((res) => {
    if (res.code == 200) {
      console.log(res, "列表11");
      window.scrollTo(0, 0);
      setTimeout(() => {
        loading.value = false;
      }, 400);
      data.typeList = res.rows;
      console.log(res.rows, "data.typeList");

      if (pageNum.value >= Math.ceil(res.total / pageSize.value)) {
        showmorecontent.value = "no more";
      }
    }
  });
};
watchEffect(() => {
  console.log(router.currentRoute.value.query?.name, "sche3");
  const name = router.currentRoute.value.query?.name;
  if (name) {
    searchListFn(name);
  }
});

// const getType = () => {
//   searchList({ cateGory: data.type }).then((res) => {
//     if (res.code == 200) {

//     }
//   })
// }
const goDetailPage = (id, item) => {
  router.push("/detailPage/" + id);
  store.commit("setroomObj", JSON.stringify(item));
};
//未登录加入登录
const gotoLogin = () => {
  bus.emit("joinLogin", true);
};

// function openShowMessage(item) {
//   bus.emit('chatting', true)
//   bus.emit('chattingObj', item.nickname)
// }

// 展示更多
function showMore() {
  if (showmorecontent.value == "no more") {
    ElMessage("no more.");
    return;
  }
  pageNum.value += 1;
  searchListFn();
}

// 添加到收藏
async function changeFollow(item, index) {
  const userInfo = JSON.parse(store.state.userInfo);
  let data = {
    userId: userInfo.id,
    consultantId: item.id,
  };
  const { code } = await request.post("/system/follower/add", data);
  // , duration: 0
  if (code == 200) {
    item.collect = "1";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Added to favorites!</div>`,
      position: "bottom-right",
    });
  }
}
// 删除收藏
async function changeFollowDel(item, index) {
  const userInfo = JSON.parse(store.state.userInfo);
  let data = {
    userId: userInfo.id,
    consultantId: item.id,
  };
  const { code } = await request.post("/system/follower/cancel", data);
  if (code == 200) {
    item.collect = "0";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Removed from favorites!</div>`,
      position: "bottom-right",
    });
  }
}

//打开发送消息弹框
function openShowMessage(item) {
  console.log(item);
  bus.emit("chatting", true);
  bus.emit("chattingObj", item.nickname);
}

//打电话
const callPhone = async (item) => {
  if (getType() == "1") {
    console.log(111);
    bus.emit("chatting", true);
    return;
  }
  console.log(item);
  const { code, data } = await request.get(
    "/system/faConsultant/info?consultantId=" + item.id
  );
  if (code == "200") {
    const { id, listImage, nickname, liveRoom, freeMinutes, perMinute } = data;
    let obj = {
      id,
      listImage,
      nickname,
      liveRoom,
      freeMinutes,
      perMinute,
    };
    console.log(obj);
    store1.isPhoneShow = true;
    store1.CounselorMessage = obj;
  }

  // console.log(store1.isPhoneShow, store1.CounselorMessage)
};
</script>

<style lang="scss" scoped>
.home-page {
  .list-word {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    li {
      width: calc(20% - 10px);
      margin-right: 10px;
      list-style: none;
      margin-bottom: 16px;
      cursor: pointer;
      border-radius: 5px;
      overflow: auto;

      .word-img {
        position: relative;
        height: 146px;

        img {
          width: 100%;
          height: 100%;
          max-height: 146px;
          display: block;
          object-fit: cover;
        }
      }

      .word-icon {
        position: absolute;
        top: 10px;
        right: 8px;
        cursor: pointer;
      }

      .word-peple {
        position: absolute;
        color: #ffffff;
        background-color: #0fd6cc;
        top: 10px;
        left: 8px;
        font-size: 12px;
        padding: 3px 6px;
        border-radius: 6px;
      }

      .word-category {
        height: 70px;
        font-size: 12px;
        background: #ffffff;

        .word-message {
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
          margin-right: 4px;

          .message-right {
            margin: 5px;
            display: flex;
            align-items: center;
            width: 50%;

            img {
              width: 30px;
              height: 30px;
              border-radius: 30px;
              margin-right: 10px;
            }

            .message-name {
              cursor: pointer;
              font-size: 12px !important;
              width: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .no-icon {
            margin-right: 6px;
            margin-top: 5px;
            width: 37%;

            p {
              text-align: center;
              padding: 8px 2px;
              background-color: #0477a5;
              border-radius: 6px;
              color: #ffffff;
              font-size: 12px;
            }
          }

          .message-icon {
            display: flex;
            margin-top: 3px;

            p {
              margin: 4px 0px;
              background-color: rgba(162, 219, 236, 0.5);
              border-radius: 18px;
              padding: 4px 6px;
              margin-right: 6px;
            }

            .p1 {
              cursor: not-allowed;
              background-color: #b6b6b6;
              pointer-events: none;
            }

            .icon1 {
              font-size: 12px;
            }
          }
        }

        .small-type {
          line-height: 20px;
          height: 20px;
          display: flex;
          flex-wrap: wrap;
          // overflow: hidden;
          overflow-y: hidden;
          border-radius: 10px;
          cursor: pointer;

          p {
            background-color: rgba(162, 219, 236, 0.5);
            margin-left: 7px;
            display: block;
            padding: 0px 6px;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .life {
    height: 25px;
    width: 75px;
    overflow: hidden;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    border: 2px solid white;
    border-radius: 50px;
    text-align: center;
    line-height: 20px;
    color: white;
  }

  .life-online {
    background-color: rgb(115, 201, 64);
  }

  .life-busy {
    background-color: red;
  }

  .life-live {
    background-color: #46a6ff;
  }

  .line-offline {
    background-color: #b6b6b6;
  }

  .show-more {
    color: #ffe400 !important;
    display: block;
    padding-top: 8px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    cursor: pointer;
    text-transform: capitalize;
    font-family: Poppins;
  }

  .show-more:before {
    right: 16px;
    margin-left: -50%;
  }

  .show-more:after {
    left: 16px;
    margin-right: -50%;
  }

  .show-more:before,
  .show-more:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    vertical-align: middle;
    background-color: #ffffff2a;
  }
}
</style>
